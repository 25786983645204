<template>
  <div>
    <b-table
      :fields="fields"
      :items="items"
      responsive
      borderless
      class="doses_table"
    >
      <template v-slot:cell(laterality)="data">
        <span>
          {{ parseLaterality(data.item.laterality) }}
        </span>
      </template>
      <template v-slot:cell(date)="data">
        <span v-if="data.item.appointment">
          {{
            moment(data.item.appointment?.start_datetime).format('DD/MM/YYYY')
          }}
        </span>
        <span v-else> - </span>
      </template>
      <template v-slot:cell(status)="data">
        <div class="d-flex align-items-start">
          <span
            :class="`status-appointment ${data.item.appointment?.status}`"
            v-if="data.item.appointment?.status"
          >
            {{ parseAppointmentStatus(data.item.appointment?.status) }}
          </span>
          <span v-else class="status-appointment"> Pendente </span>
          <b-button
            variant="primary"
            class="ml-3"
            @click="openAppointmentModal(data.item, data.item.appointment?.id)"
          >
            <v-calendar-icon class="icon" />
          </b-button>
        </div>
      </template>
    </b-table>
    <hr />
    <b-table
      v-if="observations.length"
      :fields="[{ key: 'observation', label: 'Observações' }]"
      :items="observations"
      borderless
    />
  </div>
</template>

<script>
import CalendarIcon from '@/assets/icons/calendar.svg'
import { parseLaterality } from '@/utils/eyesHelper'

export default {
  name: 'TreatmentRequestTableDoses',
  props: {
    fields: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    observations: {
      type: Array,
      required: true
    },
    openAppointmentModal: {
      type: Function,
      required: true
    }
  },
  components: {
    'v-calendar-icon': CalendarIcon
  },
  methods: {
    parseLaterality,
    parseAppointmentStatus(status) {
      return (
        {
          CONFIRMED: 'Confirmado',
          CANCELLED: 'Cancelada',
          SCHEDULED: 'Agendada',
          STATUS_FINISHED: 'Realizada',
          '': 'Pendente'
        }[status] || 'Agendada'
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.doses_table {
  :deep(thead) {
    tr {
      border-bottom: 1px solid var(--neutral-300);
      font-size: min(1vw, 14px);

      th {
        color: var(--type-active);
      }
    }
  }

  :deep(tbody) {
    tr:not(:last-child) {
      border-bottom: 1px dashed var(--neutral-300) !important;
    }
    td {
      font-size: min(1vw, 14px);
      color: var(--type-active);
      .icon {
        width: 20px;
        height: 20px;
        fill: var(--neutral-000);
      }

      .status-appointment {
        font-size: 12px;
        padding: 7px;
        width: fit-content;
        border-radius: 16px;
        background-color: var(--light-orange-200);

        &.CONFIRMED,
        &.STATUS_FINISHED {
          background-color: var(--states-success-soft-green);
        }

        &.CANCELLED {
          background-color: #ffcdc9;
        }

        &.SCHEDULED {
          background-color: var(--neutral-300);
        }
      }
    }
  }
}
</style>
